import { Link } from "react-router-dom";
import chats from '../../images/Chats.svg'
export default function AppFooter () {
  return (
    <>
    <div className="div-block-7">
      <div className="margin-bottom margin-xxlarge home">
        <div className="max-width-large">
          <div className="margin-bottom margin-small">
            <div className="text-weight-semibold dark" data-text="t46174016">BRIDGEFY SDK</div>
            <h2 className="heading-7 donate" data-text="tn669a1a5f">Are you a developer?</h2>
          </div>
          <p className="text-size-medium-4" data-text="t1ed07218">
            Does your company have a mobile app? Your tech development team can easily add the Bridgefy SDK to your app’s
            code, and make it work without Internet! Open up to new markets and revenue streams by being there for your
            users when your competitors are nowhere to be found.
          </p>
          <Link
            to="/"
            className="button-tertiary-2 margin-left margin-xxsmall w-inline-block"
            data-link="a1bc3a">
            <div className="text-block-15" data-text="tn26810cfa">Check out Bridgefy</div>
            <div className="button-tertiary_icon w-embed">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 3L11 8L6 13" stroke="CurrentColor" strokeWidth="1.5"></path>
              </svg>
            </div>
          </Link>
          </div>
        </div>
      </div>
      <div className="footer2_component-2">
        <div className="page-padding-5">
          <div className="container-large-2">
            <div className="padding-vertical padding-xxlarge">
              <div className="padding-bottom padding-xxlarge">
                <div className="w-layout-grid footer2_top-wrapper">
                  <div className="w-layout-grid footer2_column-layout">
                    <Link
                      href="index.html"
                      id="w-node-_0d78eccb-1be7-11a9-bad8-638dd9e1d10f-7529f769"
                      aria-current="page"
                      className="footer2_logo-link w-nav-brand w--current">
                      <img src={chats} loading="lazy" width="58" alt=""/>
                    </Link>
                    <div className="footer2_column">
                      <div bind="d698217b-4a7d-a883-1514-d01b5291df65" className="footer2_column-heading footer">COMPANY</div>
                      <Link bind="d698217b-4a7d-a883-1514-d01b5291df67" to="/" aria-current="page" className="footer2_link w--current">Home</Link>
                      <Link bind="8bab66fa-ab2c-f81f-d103-44519d2944f4" to="/use-cases" className="footer2_link">Use Cases</Link>
                      <Link bind="27bb6caf-925c-2cba-f954-7ab6f1dc588f" to="/faq" className="footer2_link">FAQ</Link>
                      <Link bind="d698217b-4a7d-a883-1514-d01b5291df69" to="/app" className="footer2_link">Bridgefy App</Link>
                      <a bind="d698217b-4a7d-a883-1514-d01b5291df6b" href="https://staging.bridgefy.me/blog/" className="footer2_link">Blog</a>
                      <Link to="/contact-us" className="footer2_link">Contact Us</Link>
                    </div>
                  </div>
                  <div className="footer2_right-wrapper">
                    <div className="margin-bottom margin-xxsmall">
                      <div className="footer2_column-heading footer">SUBSCRIBE</div>
                    </div>
                    <div className="margin-bottom margin-xsmall is-small-mobile sdkweb">
                      <div className="text-size-small footer">
                        <strong>Subscribe to our newsletter to get app, SDK, and company updates!</strong>
                      </div>
                    </div>
                    <div wordpress="form" className="footer2_form w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        className="form-2">
                        <div className="footer2_form-wrapper">
                          <input
                            type="email"
                            className="form-input connecting subscribe w-input"
                            maxLength="256"
                            name="email-2"
                            data-name="Email 2"
                            placeholder="Email"
                            id="email-2"
                            required=""/>
                          <input
                            type="text"
                            className="form-input connecting subscribe w-input"
                            maxLength="256"
                            name="First-Name"
                            data-name="First Name"
                            placeholder="First Name"
                            id="First-Name"
                            required=""/>
                          <Link
                            id="w-node-_54818b79-ec5b-eca7-0a6a-b36af6b68462-7529f769"
                            href="https://admin.bridgefy.me/signup"
                            target="_blank"
                            rel="noreferrer"
                            className="button-2 max-width-full red home w-button">
                            Submit
                          </Link>
                        </div>
                        <div className="text-size-tiny footer">By clicking Submit you agree to the Bridgefy
                          <Link to="/terms-of-service" className="link-20"> Terms of Service</Link> and
                          <Link to="/privacy-policy" className="link-21"> Privacy Policy</Link>.</div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line-divider-2"></div>
              <div className="padding-top padding-medium">
                <div className="footer2_bottom-wrapper">
                  <div className="w-layout-grid footer2_legal-wrapper">
                    <div id="w-node-_0d78eccb-1be7-11a9-bad8-638dd9e1d13c-7529f769" className="footer2_credit-text-2">
                      © 2022 Bridgefy, Inc.
                    </div>
                    <Link to="/privacy-policy" className="footer2_bottom-link">Privacy Policy</Link>
                    <Link to="/terms-of-service" className="footer2_bottom-link">Terms of Service</Link>
                  </div>
                  <div className="w-layout-grid footer2_socials-wrapper">
                    <a
                      href="https://www.facebook.com/bridgefy"
                      target="_blank"
                      rel="noreferrer"
                      className="footer2_social-link w-inline-block">
                      <div className="social-icon w-embed">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M16.5 6H13.5C12.9477 6 12.5 6.44772 12.5 7V10H16.5C16.6137 9.99748 16.7216 10.0504 16.7892 10.1419C16.8568 10.2334 16.8758 10.352 16.84 10.46L16.1 12.66C16.0318 12.8619 15.8431 12.9984 15.63 13H12.5V20.5C12.5 20.7761 12.2761 21 12 21H9.5C9.22386 21 9 20.7761 9 20.5V13H7.5C7.22386 13 7 12.7761 7 12.5V10.5C7 10.2239 7.22386 10 7.5 10H9V7C9 4.79086 10.7909 3 13 3H16.5C16.7761 3 17 3.22386 17 3.5V5.5C17 5.77614 16.7761 6 16.5 6Z" fill="CurrentColor"></path>
                        </svg>
                      </div>
                    </a>
                    <a
                      href="https://twitter.com/bridgefy"
                      target="_blank"
                      rel="noreferrer"
                      className="footer2_social-link w-inline-block">
                      <div className="social-icon w-embed">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M20.9728 6.7174C20.5084 7.33692 19.947 7.87733 19.3103 8.31776C19.3103 8.47959 19.3103 8.64142 19.3103 8.81225C19.3154 11.7511 18.1415 14.5691 16.0518 16.6345C13.962 18.6999 11.1312 19.8399 8.19405 19.7989C6.49599 19.8046 4.81967 19.4169 3.29642 18.6661C3.21428 18.6302 3.16131 18.549 3.16162 18.4593V18.3604C3.16162 18.2313 3.26623 18.1267 3.39527 18.1267C5.06442 18.0716 6.67402 17.4929 7.99634 16.4724C6.48553 16.4419 5.12619 15.5469 4.5006 14.1707C4.46901 14.0956 4.47884 14.0093 4.52657 13.9432C4.57429 13.8771 4.653 13.8407 4.73425 13.8471C5.19342 13.8932 5.65718 13.8505 6.1002 13.7212C4.43239 13.375 3.17921 11.9904 2.99986 10.2957C2.99349 10.2144 3.02992 10.1357 3.096 10.0879C3.16207 10.0402 3.24824 10.0303 3.32338 10.062C3.77094 10.2595 4.25409 10.3635 4.74324 10.3676C3.28184 9.40846 2.65061 7.58405 3.20655 5.92622C3.26394 5.76513 3.40181 5.64612 3.5695 5.61294C3.73718 5.57975 3.90996 5.63728 4.02432 5.76439C5.99639 7.86325 8.70604 9.11396 11.5819 9.25279C11.5083 8.95885 11.4721 8.65676 11.4741 8.35372C11.501 6.76472 12.4842 5.34921 13.9634 4.76987C15.4425 4.19054 17.1249 4.56203 18.223 5.71044C18.9714 5.56785 19.695 5.31645 20.3707 4.96421C20.4202 4.93331 20.483 4.93331 20.5325 4.96421C20.5634 5.01373 20.5634 5.07652 20.5325 5.12604C20.2052 5.87552 19.6523 6.50412 18.9509 6.92419C19.5651 6.85296 20.1685 6.70807 20.7482 6.49264C20.797 6.45942 20.8611 6.45942 20.9099 6.49264C20.9508 6.51134 20.9814 6.54711 20.9935 6.59042C21.0056 6.63373 20.998 6.68018 20.9728 6.7174Z" fill="CurrentColor"></path>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
