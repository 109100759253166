
import forbes from '../../images/forbes_1forbes.png';
import sciam from '../../images/sciam_1sciam.png'
import reuters1 from '../../images/reuters_1reuters.png'
import wsj1 from '../../images/wsj_1wsj.png'
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';

export default function ThePress() {

  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (
      <div
        style={{
          textAlign: "right",
        }}
      >
        <button onClick={previous} class="carousel-button-arrow">
          <img
            src="https://uploads-ssl.webflow.com/624380709031623bfe4aee60/624380709031620b764aee7b_icon_slider-arrow-left.svg"
            loading="lazy"
            alt=""/>
        </button>
        <button onClick={next} class="carousel-button-arrow">
        <img
          src="https://uploads-ssl.webflow.com/624380709031623bfe4aee60/6243807090316275724aee79_icon_slider-arrow-right.svg"
          loading="lazy"
          alt=""/>
        </button>
      </div>
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <section className="section-testimonial11">
      <div className="page-padding-3">
        <div className="container-large-2">
          <div className="padding-vertical padding-xhuge">
            <div className="margin-bottom margin-xxlarge presss">
              <div className="max-width-large-2">
                <div className="margin-bottom margin-small">
                  <div className="text-weight-semibold-4 homee">PRESS</div>
                  <h2 className="heading-10">Bridgefy in the Press</h2>
                </div>
              </div>
            </div>
            <div className="">
              <div className="">
                <Carousel
                  renderButtonGroupOutside
                  arrows={false}
                  containerClass="container-padding-bottom"
                  customButtonGroup={<CustomButtonGroupAsArrows />}
                  responsive={responsive} autoPlay infinite>
                  <div className="">
                    <div className="testimonial11_content">
                      <div className="margin-vertical margin-medium">
                        <div className="heading-xsmall-2">
                          <a
                            href="https://www.forbes.com/sites/johnkoetsier/2022/02/25/ukrainians-prepping-for-internet-loss-by-getting-apps-for-offline-private-mesh-communications/?sh=229fde1a791d" bind="534485ad-5faf-e8f2-0e04-ecec3ee8b834"
                            target="_blank"
                            rel="noreferrer"
                            className="link-29">
                            <strong>Ukrainians Prepping For Internet Loss By Getting Apps For Offline, Private, Mesh Communications.</strong>
                          </a>
                          <br/>Some of the apps, like Bridgefy, use Bluetooth to create a mesh network that does not depend on a functioning internet and cannot be easily disrupted by authorities.
                        </div>
                      </div>
                      <div className="testimonial11_client">
                        <div className="testimonial11_client-image-wrapper">
                          <img
                            src={forbes}
                            loading="lazy"
                            alt=""
                            className="testimonial11_client-image"/>
                          </div>
                        <div className="testimonial11_client-info">
                          <p className="text-weight-semibold-3">
                            <a
                              href="https://www.forbes.com/sites/johnkoetsier/2022/02/25/ukrainians-prepping-for-internet-loss-by-getting-apps-for-offline-private-mesh-communications/?sh=229fde1a791d"
                              target="_blank"
                              rel="noreferrer"
                              className="link-19">
                              <em className="italic-text">Forbes</em>
                            </a>
                          </p>
                          <p className="paragraph-9">Feb 25, 2022 01:01pm EST</p>
                        </div>
                        <div className="testimonial11_divider"></div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial11_slide w-slide">
                    <div className="testimonial11_content">
                      <div className="margin-vertical margin-medium">
                        <div className="heading-xsmall-2">
                          <a
                            href="https://techcrunch.com/2022/02/28/ukrainians-turn-to-encrypted-messengers-offline-maps-and-twitter-amid-russian-invasion/"
                            target="_blank"
                            rel="noreferrer"
                            className="link-29">
                            <strong>Ukrainians turn to encrypted messengers, offline maps and Twitter amid Russian invasion.</strong>
                          </a>
                          <strong><br/>‍</strong>
                          Ukrainians have turned to offline mapping and encrypted communication apps in the wake of the Russian invasion of their country, which is displacingho have left their homes to eitherto neighboring countries.
                        </div>
                      </div>
                      <div className="testimonial11_client">
                        <div className="testimonial11_client-image-wrapper">
                          <img
                            alt=""
                            className="testimonial11_client-image"/>
                          </div>
                        <div className="testimonial11_client-info">
                          <p className="text-weight-semibold-3">
                            <a
                              href="https://techcrunch.com/2022/02/28/ukrainians-turn-to-encrypted-messengers-offline-maps-and-twitter-amid-russian-invasion/"
                              target="_blank"
                              rel="noreferrer"
                              className="link-19">
                              <em className="italic-text">Tech Crunch</em>
                            </a>
                          </p>
                          <p className="paragraph-9">May 28, 2022 12:36 PM CST</p>
                        </div>
                        <div className="testimonial11_divider"></div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial11_slide w-slide">
                    <div className="testimonial11_content">
                      <div className="margin-vertical margin-medium">
                        <div className="heading-xsmall-2">
                          <a
                            href="https://www.wsj.com/articles/democracys-digital-defenses-11620403161"
                            target="_blank"
                            rel="noreferrer"
                            className="link-29">
                            <strong>Democracy’s Digital Defenses.</strong>
                          </a><br/>
                          New tools, many developed by the private sector, have the potential to thwart surveillance and disinformation by authoritarian governments
                        </div>
                      </div>
                      <div className="testimonial11_client">
                        <div className="testimonial11_client-image-wrapper">
                          <img
                            src={wsj1}
                            loading="lazy"
                            alt=""
                            className="testimonial11_client-image"/>
                          </div>
                        <div className="testimonial11_client-info">
                          <p className="text-weight-semibold-3">
                            <a
                              href="https://www.wsj.com/articles/democracys-digital-defenses-11620403161"
                              target="_blank"
                              rel="noreferrer"
                              className="link-19">
                              <em className="italic-text">The Wall Street Journal</em></a>
                          </p>
                          <p className="paragraph-9">May 7, 2021 11:59 am ET</p>
                        </div>
                        <div className="testimonial11_divider"></div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial11_slide w-slide">
                    <div className="testimonial11_content">
                      <div className="margin-vertical margin-medium">
                        <div className="heading-xsmall-2">
                          <a
                            href="https://www.forbes.com/sites/johnkoetsier/2019/09/02/hong-kong-protestors-using-mesh-messaging-app-china-cant-block-usage-up-3685/?sh=45afa55135a5"
                            target="_blank"
                            rel="noreferrer"
                            className="link-29">
                            <strong>Hong Kong Protestors Using Mesh Messaging App</strong>
                          </a><br/>
                          How do you communicate when the government censors the internet? With a peer-to-peer mesh broadcasting network that doesn&#x27;t use the internet.
                        </div>
                      </div>
                      <div className="testimonial11_client">
                        <div className="testimonial11_client-image-wrapper">
                          <img src={forbes} loading="lazy" bind="534485ad-5faf-e8f2-0e04-ecec3ee8b87b" alt="" className="testimonial11_client-image"/>
                        </div>
                        <div className="testimonial11_client-info">
                          <p className="text-weight-semibold-3">
                            <a
                              href="https://www.forbes.com/sites/johnkoetsier/2019/09/02/hong-kong-protestors-using-mesh-messaging-app-china-cant-block-usage-up-3685/?sh=5678710b135a"
                              target="_blank"
                              rel="noreferrer"
                              className="link-19">
                              <em className="italic-text">Forbes</em>
                            </a>
                          </p>
                          <p className="paragraph-9">May 7, 2021 11:59 am ET</p>
                        </div>
                        <div className="testimonial11_divider"></div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial11_slide w-slide">
                    <div className="testimonial11_content">
                      <div className="margin-vertical margin-medium">
                        <div className="heading-xsmall-2">
                          <a
                            href="https://www.reuters.com/article/us-myanmar-politics-bridgefy-idUSKBN2A22H0"
                            target="_blank"
                            rel="noreferrer"
                            className="link-29">
                            <strong>Offline message app downloaded over million times after Myanmar coup.<br/></strong>
                          </a>Offline messaging app Bridgefy said it was downloaded more than 1 million times in Myanmar...
                        </div>
                      </div>
                      <div className="testimonial11_client">
                        <div className="testimonial11_client-image-wrapper">
                          <img
                            src={reuters1}
                            loading="lazy"
                            alt="" className="testimonial11_client-image"/>
                        </div>
                        <div className="testimonial11_client-info">
                          <p className="text-weight-semibold-3">
                            <a
                              href="https://www.reuters.com/article/us-myanmar-politics-bridgefy-idUSKBN2A22H0"
                              target="_blank"
                              rel="noreferrer"
                              className="link-19">
                              <em className="italic-text">Reuters</em>
                            </a>
                          </p>
                          <p className="paragraph-9">May 7, 2021 11:59 am ET</p>
                        </div>
                        <div className="testimonial11_divider"></div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial11_slide w-slide">
                    <div className="testimonial11_content">
                      <div className="margin-vertical margin-medium">
                        <div className="heading-xsmall-2">
                          <a
                            href="https://www.scientificamerican.com/article/the-mathematics-of-how-connections-become-global/"
                            target="_blank"
                            rel="noreferrer"
                            className="link-29">
                            <strong>The Mathematics of How Connections Become Global.<br/></strong>
                          </a>
                          <strong>‍</strong>Percolation theory illuminates the behavior of many kinds of networks, from cell-phone connections to disease transmission.
                        </div>
                      </div>
                      <div className="testimonial11_client">
                        <div className="testimonial11_client-image-wrapper">
                          <img
                            src={sciam}
                            loading="lazy"
                            alt="" className="testimonial11_client-image"/>
                        </div>
                        <div className="testimonial11_client-info">
                          <p className="text-weight-semibold-3">
                            <a
                              href="https://www.scientificamerican.com/article/the-mathematics-of-how-connections-become-global/"
                              target="_blank"
                              rel="noreferrer"
                              className="link-19">
                              <em className="italic-text">Scientific American</em>
                            </a>
                          </p>
                          <p className="paragraph-9">May 7, 2021 11:59 am ET</p>
                        </div>
                        <div className="testimonial11_divider"></div>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}