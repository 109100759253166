export default function TermsOfServiceContent() {
  return(
    <>
      <section className="section-contact12 terms">
        <div className="page-padding-2 sales">
          <div className="container-large">
            <div className="padding-vertical padding-xhuge">
              <div className="margin-bottom margin-large">
                <div className="max-width-large">
                  <div style={{marginTop: '4.4rem'}} className="margin-bottom margin-xsmall sdkweb">
                    <div className="text-weight-semibold terms">COMPANY</div>
                  </div>
                  <div className="margin-bottom" style={{marginTop: '2rem', textAlign: 'left'}}>
                    <h2 className="heading-7 terms">Terms of Service</h2>
                  </div>
                  <p className="text-size-medium-3 sales terms">
                    <strong bind="409caa59-fe60-ee0d-cc91-f75aeae7f3eb">BRIDGEFY, INC.<br/><br/>Terms of Service<br/>‍</strong>
                    Last updated: June 28, 2022<br/><br/>These Terms of Service constitute a legally binding agreement
                    between you and Bridgefy, Inc. (together with its affiliates, “<strong>Bridgefy</strong>”,
                    “<strong>we</strong>,” “<strong>our</strong>” or “<strong>us</strong>”) governing your use of our
                    products, services, mobile application (the “<strong>App</strong>”), and website (the “
                    <strong>Site</strong>” and collectively with the foregoing, the “<strong>Services</strong>”).<br/><br/>
                    YOU ACKNOWLEDGE AND AGREE THAT, BY CLICKING ON THE “I AGREE” OR SIMILAR BUTTON, REGISTERING FOR AN
                    ACCOUNT, DOWNLOADING THE APP OR ANY APP UPGRADES, USING THE APP ON YOUR MOBILE DEVICE, USING
                    THE BRIDGEFY SDK IN ANY WAY, OR ACCESSING OR USING THE SERVICES, YOU ARE INDICATING THAT YOU HAVE
                    READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE, WHETHER OR NOT YOU HAVE REGISTERED
                    WITH THE SITE OR THE APP. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, THEN YOU HAVE NO RIGHT TO
                    ACCESS OR USE THE SERVICES. These Terms of Service are effective as of the date you first click
                    “I agree” (or similar button or checkbox) or use or access the Services, whichever is earlier.  
                    If you accept or agree to these Terms of Service on behalf of your employer or another legal entity,
                    you represent and warrant that (i) you have full legal authority to bind your employer or such entity
                    to these Terms of Services; (ii) you have read and understand these Terms of Service; and (iii) you
                    agree to these Terms of Service on behalf of the party that you represent.  In such event, “you” and
                    “your” will refer and apply to your employer or such other legal entity.<br/><br/>Any personal data you
                    submit to us or which we collect about you is governed by our Privacy Policy
                    (“<strong>Privacy Policy</strong>”), available at: https://bridgefy.me/privacy-policy/<br/><br/>
                    You acknowledge that by using the Services, you have reviewed and accepted the Privacy Policy.  
                    The Privacy Policy is incorporated by reference into these Terms of Service and together form and
                    are hereinafter referred to as this “<strong>Agreement.</strong>
                    ” You also acknowledge that you agree to the Bridgefy SDK License Agreement, which can be found
                    at https://bridgefy.me/sdk-license-agreement/ if applicable.<br/><br/>PLEASE NOTE: THIS AGREEMENT
                    GOVERNS HOW DISPUTES BETWEEN YOU AND BRIDGEFY CAN BE RESOLVED. IT CONTAINS A BINDING AND FINAL
                    ARBITRATION PROVISION AND CLASS ACTION WAIVER (SECTION 12). PLEASE READ CAREFULLY AS IT AFFECTS
                    YOUR LEGAL RIGHTS, INCLUDING, IF APPLICABLE, YOUR RIGHT TO OPT OUT OF ARBITRATION.<br/><br/>
                    1. <strong>Our Services<br/><br/>‍</strong>Bridgefy is a mobile messaging app that works with or without
                    Internet. It helps people stay in touch with each other, by allowing users to exchange messages
                    using Bluetooth instead of data, which is useful in places and during situations where an Internet
                    connection is impossible or not wanted. There is a free version and a paid version.<br/><br/>
                    2. <strong>Account, Password, Security, and Mobile Phone Use<br/><br/>‍</strong>You must register with
                    Bridgefy and create an account to use the Services (an “<strong>Account</strong>”) and as part of that
                    process you will be requested to provide certain information, including without limitation your name,
                    phone number and email address.  By using the Services, you agree to provide true, accurate, current
                    and complete information as prompted by the registration process and to maintain and promptly update
                    the Account information to keep it accurate, current and complete. You are the sole authorized user
                    of your Account. You are responsible for maintaining the confidentiality of any log-in, password,
                    and Account number provided by you or given to you by Bridgefy for accessing the Services. You are
                    solely and fully responsible for all activities that occur under your password or Account, even if
                    not authorized by you. Bridgefy has no control over the use of any user’s Account and expressly
                    disclaims any liability derived therefrom. Should you suspect that any unauthorized party may be
                    using your password or Account or you suspect any other breach of security, you agree to contact
                    Bridgefy immediately.<br/><br/>The person signing up for the Services will be the contracting party
                    (“<strong>Account Owner</strong>”) for the purposes of these Terms of Service and will be the
                    person who is authorized to use any corresponding Account we provide to the Account Owner in
                    connection with the Services; provided, however, that if you are signing up for the Services on
                    behalf of your employer, your employer shall be the Account Owner. As the Account Owner, you are
                    solely responsible for complying with these Terms of Service and only you are entitled to all
                    benefits accruing thereto. Your Account is not transferable to any other person or account.
                    You must immediately notify us of any unauthorized use of your password or identification or any
                    other breach or threatened breach of our security or the security of your Account.<br/><br/>
                    3. <strong>Billing and Payment<br/><br/>‍</strong>Payment and any other expenses must be paid
                    through the third party payment processing system (the “<strong>PSP</strong>”) as indicated on the
                    Services.  You may be required to register with the PSP, agree to terms of service of the PSP,
                    provide your payment details to the PSP and go through a vetting process at the request of the PSP
                    to set up an account with the PSP (the “<strong>PSP Services Agreement</strong>”). By accepting
                    these Terms of Service, you agree that you have downloaded or printed, and reviewed and agreed to,
                    the PSP Services Agreement. Please note that Bridgefy is not a party to the PSP Services Agreement
                    and that you, the PSP and any other parties listed in the PSP Services Agreement are the parties to
                    the PSP Services Agreement and that Bridgefy has no obligations, responsibility or liability to any
                    user or any other party under the PSP Services Agreement.<br/><br/>All prices and fees displayed on the
                    Services are exclusive of applicable federal, provincial, state, local or other governmental sales,
                    goods and services or other taxes, fees or charges now in force or enacted in the future
                    (“<strong>Taxes</strong>”).  Any applicable Taxes are based on the rates applicable to the billing
                    address you provide to us, and will be calculated at the time of a transaction is charged to your
                    Account.  Unless otherwise indicated, all prices, fees and other charges are in U.S. dollars, and
                    all payments shall be in U.S. currency.<br/><br/>
                    4. <strong>Representations and Warranties<br/><br/>‍</strong> You represent and warrant that:
                    (i) you are 18 years of age or older or are at least of the legally required age in the jurisdiction
                    in which you reside, and are otherwise capable of entering into binding contracts, and (ii) you have
                    the right, authority and capacity to enter into this Agreement and to abide by the terms and conditions
                    of this Agreement, and that you will so abide. Where you enter into this Agreement on behalf of a
                    company or other organization, you represent and warrant that you have authority to act on behalf of
                    that entity and to bind that entity to this Agreement.<br/><br/>You further represent and warrant that
                    (i) you have read, understand, and agree to be bound by these Terms of Service and the Privacy Policy
                    in order to access and use the Services, (ii) you will act professionally and responsibly in your
                    interactions with other users, and (iii) when using or accessing the Services, you will act in accordance
                    with any applicable local, state, or federal law or custom and in good faith.<br/><br/>You agree not to
                    engage in any of the following prohibited activities, among others: (i) copying, distributing, or
                    disclosing any part of the Services in any medium other than as allowed by the Services and these
                    Terms of Service; (ii) using any automated system (other than any functionalities of the Services),
                    including without limitation “robots,” “spiders,” “offline readers,” etc., to access the Services;
                    (iii) transmitting spam, chain letters, or other unsolicited email or attempting to phish, pharm,
                    pretext, spider, crawl, or scrape; (iv) attempting to interfere with, compromise the system integrity
                    or security or decipher any transmissions to or from the servers running the Services; (v) violating
                    any international, federal, provincial or state regulations, rules, laws, or local ordinances; (vi)
                    conducting any unlawful purposes or soliciting others to perform or participate in any unlawful
                    acts; (vii) uploading invalid data, viruses, worms, or other software agents through the Services;
                    (viii) infringing upon or violate our intellectual property rights or the intellectual property
                    rights of others; (ix) impersonating another person or otherwise misrepresenting your affiliation
                    with a person or entity, conducting fraud, hiding or attempting to hide your identity; (x) harassing,
                    insulting, harming, abusing, defaming, abusing, harassing, stalking, threatening, intimidating or
                    otherwise violating the legal rights (such as of privacy and publicity) of any other users or
                    visitors of the Services or staff member of Bridgefy; (xi) interfering with or any activity that
                    threatens the performance, security or proper functioning of the Services; (xii) uploading or
                    transmitting viruses or any other type of malicious code; (xiii) attempting to decipher, decompile,
                    disassemble or reverse engineer any of the software or algorithms used to provide the Services;
                    (xiv) bypassing the security features or measures we may use to prevent or restrict access to the
                    Services, including without limitation features that prevent or restrict use or copying of any
                    content or enforce limitations on use of the Services or the content therein; (xv) attempting to
                    access unauthorized Accounts or to collect or track the personal information of others; (xvi) using
                    the Services for any purpose or in any manner that infringes the rights of any third party; or
                    (xvii) encouraging or enabling any other individual to do any of the foregoing.<br/><br/>You hereby
                    warrant and represent that, other than as fully and promptly disclosed to Bridgefy as set
                    forth below, you do not have any motivation, status, or interest which Bridgefy may reasonably
                    wish to know about in connection with the Services, including without limitation, if you are using
                    or will or intend to use the Services for any journalistic, investigative, or unlawful purpose.
                    You hereby warrant and represent that you will promptly disclose to Bridgefy in writing any such
                    motivation, status or interest, whether existing prior to registration or as arises during your
                    use of the Services.<br/><br/>
                    5. <strong>Mobile App Updates and Upgrades<br/><br/>‍</strong>By installing
                    the App, you consent to the installation of the App and any updates or upgrades that are released
                    through the Services. The App (including any updates or upgrades) may (i) cause your device to
                    automatically communicate with Bridgefy’s servers to deliver the App functionality and to record
                    usage metrics, (ii) affect App-related preferences or data stored your device, and (iii) collect
                    personal information as set out in our Privacy Policy, including location information. You can
                    uninstall the App at any time.<br/><br/>
                    6. <strong>Termination and Suspension<br/><br/>‍</strong>Unless otherwise agreed to in writing between
                    you and Bridgefy, either party may terminate these Terms of Service for any or no cause, at
                    any time.  You may cancel and delete your Account at any time by either using the features
                    on the Services to do so (if applicable and available) or by written notice to contact@bridgefy.me.
                    After cancellation, you will no longer have access to your Account, your profile or any other
                    information through the Services. The provisions of these Terms of Service which by their intent
                    or meaning intended to survive such termination, including without limitation the provisions
                    relating to disclaimer of warranties, limitations of liability, and indemnification, shall survive
                    the any termination of these Terms of Service and any termination of your use of or subscription to
                    the Services and shall continue to apply indefinitely.<br/><br/>We reserve the right to refuse the
                    Services to anyone for any reason at any time.  Bridgefy may terminate or limit your right to use
                    the Services in the event that we are investigating or believe that you have breached any provision
                    of this Agreement, by providing you with written or email notice. Such termination or limitation will
                    be effective immediately upon delivery of such notice.  If Bridgefy terminates or limits your right
                    to use the Services pursuant to this section, you are prohibited from registering and creating a new
                    Account under your name, a fake or borrowed name, or the name of any third party, even if you may be
                    acting on behalf of the third party.<br/><br/>Even after your right to use the Services is terminated
                    or limited, this Agreement will remain enforceable against you. Bridgefy reserves the right to take
                    appropriate legal action, including but not limited to pursuing arbitration in accordance with
                    Section 12 of these Terms of Service.<br/><br/>Bridgefy reserves the right to modify or discontinue,
                    temporarily or permanently, all or any portion of the Services at its sole discretion. Bridgefy
                    is not liable to you for any modification or discontinuance of all or any portion of the Services.
                    Bridgefy has the right to restrict anyone from completing registration as a user if Bridgefy
                    believes such person may threaten the safety and integrity of the Services, or if, in Bridgefy’s
                    discretion, such restriction is necessary to address any other reasonable business concern.<br/><br/>
                    Following the termination or cancellation of your Account (as defined below), we reserve the right
                    to delete all your data, in the normal course of operation. Your data cannot be recovered once your
                    Account is terminated or cancelled.<br/><br/>
                    7. <strong>Links to Third-Party Websites<br/><br/>‍</strong>
                    The Services may contain links (such as hyperlinks) to third-party websites. Such links do not
                    constitute endorsement by Bridgefy or association with those websites, their content or their
                    operators. Such links (including without limitation external websites that are framed by the Services
                    as well as any advertisements displayed in connection therewith) are provided as an information
                    service, for reference and convenience only. Bridgefy does not control any such websites, and is
                    not responsible for their (i) availability or accuracy, or (ii) content, advertising, products, or
                    services. It is your responsibility to evaluate the content and usefulness of the information obtained
                    from other websites. You acknowledge and agree that Bridgefy is not involved in the creation or
                    development of third-party websites and disclaims any responsibility for third-party websites, and
                    cannot be liable for claims arising out of or relating to third-party websites. Further, you acknowledge
                    and agree that Bridgefy has no obligation to monitor, review, or remove links to third-party websites,
                    but reserves the right to limit or remove links to third-party websites on the Services at its sole
                    discretion.<br/><br/>The use of any website controlled, owned or operated by third parties is governed
                    by the terms and conditions of use and privacy policies for those websites. You access such third-party
                    websites at your own risk. Bridgefy expressly disclaims any liability arising in connection with your
                    use and/or viewing of any websites or other material associated with links that may appear on the
                    Services. You hereby agree to hold Bridgefy harmless from any liability that may result from the
                    use of links that may appear on the Services.<br/><br/>As part of the functionality of the Services,
                    you may link your Account with online accounts you may have with third-party service providers, such
                    as Google (each such account, a “<strong>Third-Party Account</strong>”) by either: (i) providing your
                    Third-Party Account login information through the Services; or (ii) allowing Bridgefy to access your
                    Third-Party Account, as is permitted under the applicable terms and conditions that govern your use of
                    each Third-Party Account. You represent that you are entitled to disclose your Third-Party Account
                    login information to Bridgefy and/or grant Bridgefy access to your Third-Party Account (including,
                    but not limited to, for use for the purposes described herein), without breach by you of any of the
                    terms and conditions that govern your use of the applicable Third-Party Account and without obligating
                    Bridgefy to pay any fees or making Bridgefy subject to any usage limitations imposed by such third-party
                    service providers. By granting Bridgefy access to any Third-Party Accounts, you understand that (1)
                    Bridgefy may access, make available and store (if applicable) any content that you have provided to
                    and stored in your Third-Party Account (the “<strong>SNS Content</strong>”) so that it is available on
                    and through the Services via your Account, including without limitation any friend lists, and (2)
                    Bridgefy may submit and receive additional information to your Third-Party Account to the extent you
                    are notified of this when you link your Account with the Third-Party Account. Depending on the Third-Party
                    Accounts you choose, and subject to the privacy settings that you have set in such Third-Party Accounts,
                    personally identifiable information that you post to your Third-Party Accounts may be available on and
                    through your Account on the Services. Please note that if a Third-Party Account or associated service
                    becomes unavailable or Bridgefy’s access to such Third-Party Account is terminated by the third-party s
                    ervice provider, then SNS Content may no longer be available on and through the Services. You will have
                    the ability to disable the connection between your Account on the Services and your Third-Party Accounts
                    at any time, as set forth below. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY PROVIDERS
                    ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD
                    PARTY PROVIDERS. Bridgefy makes no effort to review any SNS Content for any purpose, including but
                    not limited to, for accuracy, legality, or non-infringement, and Bridgefy is not responsible for any
                    SNS Content.<br/><br/>
                    8. <strong>Intellectual Property Rights<br/><br/>‍</strong>All text, graphics, editorial content, data,
                    formatting, graphs, designs, HTML, look and feel, photographs, music, sounds, images, software,
                    videos, designs, trademarks, logos, typefaces and other content (collectively “
                    <strong>Proprietary Material</strong>”) that users see or read through the Services is owned by
                    Bridgefy excluding User Generated Content, which users hereby grant Bridgefy a license to use.
                    Proprietary Material is protected in all forms, media and technologies now known or hereinafter
                    developed. Bridgefy owns all Proprietary Material, as well as the coordination, selection,
                    arrangement and enhancement of such Proprietary Materials as a Collective Work under the United
                    States Copyright Act, as amended. The Proprietary Material is protected by the domestic and international
                    laws governing copyright, patents, and other proprietary rights. You may not copy, download, use,
                    redesign, reconfigure, or retransmit anything from the Services without Bridgefy express prior written
                    consent.<br/><br/>Any use of such Proprietary Material, other than as permitted therein, is expressly
                    prohibited without the prior permission of Bridgefy.<br/><br/>The service marks and trademarks of Bridgefy,
                    including without limitation Bridgefy and Bridgefy logos, are service marks owned by Bridgefy. Any other
                    trademarks, service marks, logos and/or trade names appearing via the Services are the property of their
                    respective owners. You may not copy or use any of these marks, logos or trade names without the express
                    prior written consent of the owner.<br/><br/>Additionally, you may choose to or we may invite you to submit
                    comments, ideas, or feedback about the Services, including without limitation about how to improve our
                    services or our products (“<strong>Feedback</strong>”). By submitting any Feedback, you agree that
                    your disclosure is gratuitous, unsolicited, and without restriction and will not place Bridgefy
                    under any fiduciary or other obligation, and that we are free to use the Feedback without any
                    additional compensation to you, and/or to disclose the Feedback on a non-confidential basis or
                    otherwise to anyone. You further acknowledge that, by acceptance of your submission, Bridgefy does
                    not waive any rights to use similar or related Feedback previously known to Bridgefy, developed by
                    its employees, or obtained from sources other than you. You acknowledge that all email and other
                    correspondence that you submit to us shall become our sole and exclusive property.<br/><br/>Subject
                    to the terms and conditions hereof, you are hereby granted a limited, nonexclusive, nontransferable,
                    freely revocable, license to access and use the Services.  We may terminate this license at any time
                    for any reason or no reason.  The Services and all materials therein or transferred thereby, including,
                    without limitation, software, images, text, graphics, illustrations, logos, patents, trademarks,
                    service marks, reports generated by the Services, and copyrights (the “<strong>Bridgefy Content</strong>”),
                    and all Intellectual Property Rights (as defined below) related thereto, are the exclusive property of
                    Bridgefy or, as applicable, its licensors. Except as explicitly provided herein, nothing in this
                    Agreement shall be deemed to create a license in or under any such Intellectual Property Rights,
                    and you agree not to sell, license, rent, modify, publicly distribute, publicly transmit, publicly
                    display, publicly perform, publish, adapt, edit or create derivative works from any materials or
                    content accessible on the Services. Use of the Bridgefy Content or materials on the Services for any
                    purpose not expressly permitted by this Agreement is strictly prohibited. For the purposes of this
                    Agreement, “<strong>Intellectual Property Rights</strong>” means all patent rights, copyright
                    rights, mask work rights, moral rights, rights of publicity, trademark, trade dress and service
                    mark rights, goodwill, trade secret rights and other intellectual property rights as may now exist
                    or hereafter come into existence, and all applications therefore and registrations, renewals and
                    extensions thereof, under the laws of any state, country, territory or other jurisdiction.<br/><br/>
                    Your use of the Services and the relating licenses granted hereunder are also conditioned upon your
                    strict adherence to the letter and spirit of the various applicable guidelines and any end user
                    licenses associated with your use of the App. Bridgefy may modify such guidelines in its sole
                    discretion at any time. Bridgefy reserves the right to terminate your Account and access to the
                    Services if it determines that you have violated any such applicable guidelines.<br/><br/>
                    9. <strong>Confidential Information<br/><br/>‍</strong>You acknowledge that Confidential Information
                    (as defined below) is a valuable, special and unique asset of Bridgefy and agree that you will not
                    disclose, transfer, use (or seek to induce others to disclose, transfer or use) any Confidential
                    Information for any purpose other than using the Services in accordance with these Terms of Service.
                    If relevant, you may disclose the Confidential Information to your authorized employees and agents
                    provided that they are also bound to maintain the confidentiality of Confidential Information.
                    You shall promptly notify Bridgefy in writing of any circumstances that may constitute unauthorized
                    disclosure, transfer, or use of Confidential Information. You shall use best efforts to protect
                    Confidential Information from unauthorized disclosure, transfer or use. You shall return all originals
                    and any copies of any and all materials containing Confidential Information to Bridgefy upon
                    termination of this Agreement for any reason whatsoever.<br/><br/>The term “
                    <strong>Confidential Information</strong>” shall mean any and all of Bridgefy’s trade secrets,
                    confidential and proprietary information, and all other information and data of Bridgefy that is
                    not generally known to the public or other third parties who could derive value, economic or otherwise,
                    from its use or disclosure. Confidential Information shall be deemed to include technical data,
                    know-how, research, product plans, products, services, customers, markets, software, developments,
                    inventions, processes, formulas, technology, designs, drawings, engineering, hardware configuration
                    information, marketing, finances, strategic and other proprietary and confidential information
                    relating to Bridgefy or Bridgefy’s business, operations or properties, including information
                    about Bridgefy’s staff, users or partners, or other business information disclosed directly or
                    indirectly in writing, orally or by drawings or observation.<br/><br/>
                    10. <strong>Disclaimer of Warranties<br/><br/>‍</strong>THE SERVICES IS PROVIDED ON AN “AS IS” BASIS
                    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                    LIMITED TO, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                    NON-INFRINGEMENT. BRIDGEFY MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS
                    OF THE CONTENT PROVIDED THROUGH THE SERVICES OR THE CONTENT OF ANY SITES LINKED TO THE SERVICES
                    AND ASSUMES NO LIABILITY OR RESPONSIBILITY IN CONTRACT, WARRANTY OR IN TORT FOR ANY (I) ERRORS,
                    MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
                    RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (III) ANY ACCESS TO OR USE OF OUR SECURE SERVERS
                    AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; AND (IV) EVENTS
                    BEYOND OUR REASONABLE CONTROL.<br/><br/>UNDER NO CIRCUMSTANCES WILL BRIDGEFY AND AFFILIATES OR THEIR
                    CORPORATE PARTNERS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, ACTUAL, CONSEQUENTIAL, ECONOMIC,
                    SPECIAL OR EXEMPLARY DAMAGES (INCLUDING BUT NOT LIMITED TO LOST PROFITS, LOSS OF DATA, LOSS OF GOODWILL,
                    SERVICE INTERRUPTION, COMPUTER DAMAGE, SYSTEM FAILURE, FAILURE TO STORE ANY INFORMATION OR OTHER
                    CONTENT MAINTAINED OR TRANSMITTED BY BRIDGEFY, OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES)
                    ARISING IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE THE SERVICES, EVEN IF ADVISED OF THE
                    POSSIBILITY OF THE SAME. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
                    OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU IN THEIR ENTIRETY.<br/><br/>
                    IF, NOTWITHSTANDING THE FOREGOING EXCLUSIONS, IT IS DETERMINED THAT BRIDGEFY AND AFFILIATES OR THEIR
                    CORPORATE PARTNERS ARE LIABLE FOR DAMAGES, IN NO EVENT WILL THE AGGREGATE LIABILITY, WHETHER ARISING
                    IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EXCEED THE LOWER OF (I) THE TOTAL FEES PAID BY YOU
                    TO BRIDGEFY DURING THE SIX MONTHS PRIOR TO THE TIME SUCH CLAIM AROSE OR (II) ONE HUNDRED DOLLARS ($100),
                     TO THE EXTENT PERMITTED BY APPLICABLE LAW.<br/><br/>
                     11. <strong>Indemnification<br/><br/>‍</strong>You hereby agree to indemnify, defend, and hold harmless
                     Bridgefy and its officers, directors, employees, agents, attorneys, insurers, successors and assigns
                     (the “<strong>Indemnified Parties</strong>”) from and against any and all Liabilities incurred
                     in connection with (i) your use or inability to use the Services, or (ii) your breach or violation
                     of this Agreement; (iii) your violation of any law, or the rights of any user or third party and (iv)
                     any content submitted by you or using your Account to the Services, including, but not limited to
                     the extent such content may infringe on the intellectual rights of a third party or otherwise be
                     illegal or unlawful. You also agree to indemnify the Indemnified Parties for any Liabilities resulting
                     from your use of software robots, spiders, crawlers, or similar data gathering and extraction tools,
                     or any other action you take that imposes an unreasonable burden or loan on our infrastructure.
                     Bridgefy reserves the right, in its own sole discretion, to assume the exclusive defense and control
                     at its own expense of any matter otherwise subject to your indemnification. You will not, in any event,
                     settle any claim or matter without the prior written consent of Bridgefy.<br/><br/>
                     12. <strong>Dispute Resolution – Arbitration &amp; Class Action Waiver<br/><br/>‍</strong>
                     PLEASE READ THIS SECTION CAREFULLY — IT AFFECTS YOUR LEGAL RIGHTS AND GOVERNS HOW YOU AND
                     BRIDGEFY CAN BRING CLAIMS AGAINST EACH OTHER. THIS SECTION WILL, WITH LIMITED EXCEPTION,
                     REQUIRE YOU AND BRIDGEFY TO SUBMIT CLAIMS AGAINST EACH OTHER TO BINDING AND FINAL ARBITRATION
                     ON AN INDIVIDUAL BASIS.<br/><br/>You agree that, in the event any dispute or claim arises out
                     of or relating to your use of the Services, you will contact us at contact@bridgefy.me and you
                     and Bridgefy will attempt in good faith to negotiate a written resolution of the matter directly.
                     You agree that if the matter remains unresolved for 30 days after notification (via certified mail
                     or personal delivery), such matter will be deemed a “Dispute” as defined below.  Except for the
                     right to seek injunctive or other equitable relief described under the “Binding Arbitration”
                     section below, should you file any arbitration claims, or any administrative or legal actions
                     without first having attempted to resolve the matter by mediation, then you agree that you will
                     not be entitled to recover attorneys’ fees, even if you may have been entitled to them otherwise.
                     <br/><br/>‍<strong>Binding Arbitration.</strong> You and Bridgefy agree that any dispute,
                     claim or controversy arising out of or relating to this Agreement or to your use of the
                     Services (collectively “<strong>Disputes</strong>”) will be settled by binding arbitration,
                     except that each party retains the right to seek injunctive or other equitable relief in a
                     court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation,
                     or violation of a party’s copyrights, trademarks, trade secrets, patents, or other intellectual
                     property rights. <strong>This means that you and Bridgefy both agree to waive the right to a trial
                    by jury.</strong> Notwithstanding the foregoing, you may bring a claim against Bridgefy in “
                    small claims” court, instead of by arbitration, but only if the claim is eligible under the
                    rules of the small claims court and is brought in an individual, non-class, and non-representative
                    basis, and only for so long as it remains in the small claims court and in an individual, non-class,
                    and non-representative basis.<br/><br/>‍<strong>Class Action Waiver.</strong> You and Bridgefy
                    agree that any proceedings to resolve Disputes will be conducted on an individual basis and
                    not in a class, consolidated, or representative action. <strong>This means that you and Bridgefy
                    both agree to waive the right to participate as a plaintiff as a class member
                    in any class action proceeding. Further, unless you and Bridgefy agree otherwise in
                    writing, the arbitrator in any Dispute may not consolidate more than one person’s claims and
                    may not preside over any form of class action proceeding.<br/><br/>Arbitration Administration
                    and Rules.</strong> The arbitration will be administered by the American Arbitration
                    Association (“<strong >AAA</strong>”) in accordance with the Commercial Arbitration Rules and
                    the Supplementary Procedures for Consumer Related Disputes (the “<strong>AAA Rules</strong>”)
                    then in effect, except as modified by this “Dispute Resolution’ section. (The AAA Rules are
                    available at <a href="http://www.adr.org/" target="_blank" rel="noreferrer" className="link-30">http://www.adr.org</a>
                    or by calling the AAA at 1-800-778-7879).<br/><br/>‍<strong>Arbitration Process.</strong> A party
                    who desires to initiate the arbitration must provide the other party with a written Demand for
                    Arbitration as specified in the AAA Rules. The arbitrator will be either a retired judge or an
                    attorney licensed to practice law in the state of California and will be selected by the parties
                    from the AAA’s roster of arbitrators with relevant experience. If the parties are unable to agree
                    upon an arbitrator within seven days of delivery of the Demand for Arbitration, then the AAA will
                    appoint the arbitrator in accordance with AAA Rules.<br/><br/>
                    ‍<strong>Arbitration Location and Procedure.</strong> Unless you and Bridgefy agree otherwise, the seat
                    of the arbitration shall be in Miami, Florida. If your claim does not exceed USD$10,000, then
                    the arbitration will be conducted solely on the basis of documents you and Bridgefy submit to
                    the arbitrator, unless you request a hearing and the arbitrator then determines that a hearing
                    is necessary. If your claim exceeds USD$10,000, your right to a hearing will be determined by
                    AAA Rules. Subject to AAA Rules, the arbitrator will have the discretion to direct a reasonable
                    exchange of information by the parties, consistent with the expedited nature of the arbitration.
                    Hearings may be conducted by telephone or video conference, if requested and agreed to by the
                    parties.<strong>Arbitrator’s Decision and Governing Law.</strong> The arbitrator shall apply
                    California law consistent with the Federal Arbitration Act and applicable statutes of limitations,
                    and shall honor claims of privilege recognized by law. The arbitrator will render an award within
                    the timeframe specified in the AAA Rules. Judgment on the arbitration may be entered in any court
                    having jurisdiction thereof. Any award of damages by an arbitrator must be consistent with the
                    “Disclaimers and Limitations of Liability” section above. The arbitrator may award declaratory
                    or injunctive relief in favor of the claimant only to the extent necessary to provide relief
                    warranted by the claimant’s individual claim.<br/><br/>‍<strong>Fees.</strong> Each party’s
                    responsibility to pay the arbitration filing, administrative and arbitrator fees will depend on
                    the circumstances of the arbitration and are set forth in the AAA Rules.<br/><br/>
                    13. <strong>Governing Law<br/>‍<br/>‍</strong>Except as provided in Section 12 or expressly provided
                    in writing otherwise, this Agreement and your use of the Services will be governed by, and will be
                    construed under, the laws of the State of California, without regard to choice of law principles.
                    This choice of law provision is only intended to specify the use of California law to interpret
                    this Agreement.<br/><br/>
                    14. <strong>No Agency; No Employment<br/><br/>‍</strong>No agency, partnership, joint venture,
                    employer-employee or franchiser-franchisee relationship is intended or created by this Agreement.
                    <br/><br/>
                    15. <strong>General Provisions<br/><br/>‍</strong>Failure by Bridgefy to enforce any provision(s)
                    of this Agreement will not be construed as a waiver of any provision or right. This Agreement
                    constitutes the complete and exclusive agreement between you and Bridgefy with respect to its
                    subject matter, and supersedes and governs any and all prior agreements or communications.
                    The provisions of this Agreement are intended to be interpreted in a manner which makes them
                    valid, legal, and enforceable. Except for the “Class Action Waiver” in Section 12, in the event
                    any provision is found to be partially or wholly invalid, illegal or unenforceable, (i) such
                    provision shall be modified or restructured to the extent and in the manner necessary to render
                    it valid, legal, and enforceable or, (ii) if such provision cannot be so modified or restructured,
                    it shall be excised from the Agreement without affecting the validity, legality or enforceability
                    of any of the remaining provisions. This Agreement may not be assigned or transferred by you without
                    our prior written approval. We may assign or transfer this Agreement without your consent, including
                    but not limited to assignments: (1) to a parent or subsidiary, (2) to an acquirer of assets, or (3)
                    to any other successor or acquirer. Any assignment in violation of this section shall be null and
                    void. This Agreement will inure to the benefit of Bridgefy, its successors and assigns.<br/><br/>
                    16. <strong>Changes to this Agreement and the Services<br/><br/>‍</strong>Bridgefy reserves the
                    right, at its sole and absolute discretion, to change, modify, add to, supplement, suspend,
                    discontinue, or delete any of the terms and conditions of this Agreement (including these Terms
                    of Service and Privacy Policy) and review, improve, modify or discontinue, temporarily or permanently,
                    the Services or any content or information through the Services at any time, effective with or
                    without prior notice and without any liability to Bridgefy. Bridgefy will endeavor to notify you
                    of material changes by email, but will not be liable for any failure to do so. If any future
                    changes to this Agreement are unacceptable to you or cause you to no longer be in compliance
                    with this Agreement, you must terminate, and immediately stop using, the Services. Your continued
                    use of the Services following any revision to this Agreement constitutes your complete and
                    irrevocable acceptance of any and all such changes. Bridgefy may also impose limits on certain
                    features or restrict your access to part or all of the Services without notice or liability.<br/><br/>
                    17. <strong>No Rights of Third Parties<br/><br/>‍</strong>None of the terms of this Agreement are
                    enforceable by any persons who are not a party to this Agreement.<br/><br/>
                    18. <strong>Notices and Consent to Receive Notices Electronically<br/><br/>‍</strong>
                    You consent to receive any agreements, notices, disclosures and other communications
                    (collectively, “<strong>Notices</strong>”) to which this Agreement refers electronically
                    including without limitation by e-mail or by posting Notices on this Site. You agree
                    that all Notices that we provide to you electronically satisfy any legal requirement that such
                    communications be in writing. Unless otherwise specified in this Agreement, all notices under
                    this Agreement will be in writing and will be deemed to have been duly given when received,
                    if personally delivered or sent by certified or registered mail, return receipt requested;
                    when receipt is electronically confirmed, if transmitted by facsimile or email; or the day
                    after it is sent, if sent for next day delivery by a recognized overnight delivery service.<br/><br/>
                    19. <strong>Contacting Us<br/><br/>‍</strong>If you have any questions about these Terms of
                    Service or about the Services, please contact us by email at
                    <a href="mailto:contact@bridgefy.me" className="link-28">contact@bridgefy.me</a> or by mail to 936
                    SW 1st Ave. PMB 186, Miami, Florida 33130 United States.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}